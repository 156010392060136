<template>
  <div class="subscribeBox">
    <div class="bigtop">
      {{
        styleFlag
          ? '你授权的小程序，符合第三方一键配置规则，请点击一键配置，完成下面所有订阅消息配置'
          : '请你登录微信公众号平台，增加小程序类目，为：商家自营 > 服装/鞋/箱包；配置完成后，可以一键配置完成。'
      }}
      <el-popover placement="top" width="230" trigger="hover" content="该类目为微信第三方指定类目，不添加，订阅消息功能无法使用">
        <i style="color: #417be6; cursor: pointer" v-if="!styleFlag" slot="reference" class="el-icon-question"></i>
      </el-popover>
      <p class="looktips" @click="lookexamplesMethond(0)">查看设置教程</p>
      <el-button :type="styleFlag ? 'primary' : ''" :disabled="!styleFlag" @click="oneSetting">一键设置</el-button>
    </div>
    <div class="bigBot">
      <div class="bigBotLeft">
        <img v-show="nowActive === 1" src="@/assets/image/shopDingYue.png" alt="" />
        <img v-show="nowActive === 0" src="@/assets/image/userdingyueSj.png" alt="" />
      </div>
      <div class="bigBotRight">
        <div class="chooseBox">
          <div @click="nowActive = 0" :class="nowActive === 0 ? 'isActive' : ''">用户通知</div>
          <div @click="nowActive = 1" :class="nowActive === 1 ? 'isActive' : ''">商家通知</div>
        </div>
        <div v-show="nowActive === 1" class="firstBox">
          <div class="formItem">
            <div class="title">
              <p>订单发货通知：</p>
            </div>
            <el-input v-model="shop.order_send"></el-input>
            <p class="looktips" @click="lookexamplesMethond(1)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>售后申请通知：</p>
            </div>
            <el-input v-model="shop.sales_after"></el-input>
            <p class="looktips" @click="lookexamplesMethond(2)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>商家openID：</p>
            </div>
            <el-input style="width: 330px" v-model="shop.openID" disabled></el-input>
            <el-button type="primary" @click="dialogVisible = true">选择</el-button>
          </div>
        </div>
        <div v-show="nowActive === 0" class="firstBox">
          <div class="topTitle">
            <h3>订单通知</h3>
          </div>
          <div class="formItem">
            <div class="title">
              <p>待付款提醒：</p>
            </div>
            <el-input v-model="user.wait_pay"></el-input>
            <p class="looktips" @click="lookexamplesMethond(3)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>订单取消通知：</p>
            </div>
            <el-input v-model="user.order_cancle"></el-input>
            <p class="looktips" @click="lookexamplesMethond(4)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>付款成功通知：</p>
            </div>
            <el-input v-model="user.order_pay"></el-input>
            <p class="looktips" @click="lookexamplesMethond(5)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>订单发货通知：</p>
            </div>
            <el-input v-model="user.order_send"></el-input>
            <p class="looktips" @click="lookexamplesMethond(6)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>即将自动确认收货通知：</p>
            </div>
            <el-input v-model="user.order_finish"></el-input>
            <p class="looktips" @click="lookexamplesMethond(7)">查看配置示例</p>
          </div>
        </div>
        <div v-show="nowActive === 0" class="firstBox">
          <h3>退货通知</h3>
          <div class="formItem">
            <div class="title">
              <p>退款申请驳回通知：</p>
            </div>
            <el-input v-model="user.refund_reject"></el-input>
            <p class="looktips" @click="lookexamplesMethond(8)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>退款成功通知：</p>
            </div>
            <el-input v-model="user.refund_success"></el-input>
            <p class="looktips" @click="lookexamplesMethond(9)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>退款失败通知：</p>
            </div>
            <el-input v-model="user.refund_defail"></el-input>
            <p class="looktips" @click="lookexamplesMethond(10)">查看配置示例</p>
          </div>
        </div>
        <div v-show="nowActive === 0" class="firstBox">
          <h3>支付通知</h3>
          <div class="formItem">
            <div class="title">
              <p>充值成功提醒：</p>
            </div>
            <el-input v-model="user.recharge_success"></el-input>
            <p class="looktips" @click="lookexamplesMethond(11)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>提现申请通知：</p>
            </div>
            <el-input v-model="user.withdraw_submit"></el-input>
            <p class="looktips" @click="lookexamplesMethond(12)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>提现成功通知：</p>
            </div>
            <el-input v-model="user.withdraw_success"></el-input>
            <p class="looktips" @click="lookexamplesMethond(13)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>提现失败通知：</p>
            </div>
            <el-input v-model="user.withdraw_fail"></el-input>
            <p class="looktips" @click="lookexamplesMethond(14)">查看配置示例</p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择商家" :visible.sync="dialogVisible" width="52%">
      <ListComponents v-if="dialogVisible" :styleMethon="5" @getListItem="getListItem"></ListComponents>
    </el-dialog>
    <!-- <el-button type="primary" class="last" @click="preservation">保存</el-button> -->
    <Preservation @preservation="preservation"></Preservation>
    <LookExamples ref="LookExamples" v-show="lookexamples" :showIndex="showIndex" @hidden="hiddenexamples"></LookExamples>
  </div>
</template>

<script>
import LookExamples from '@/components/previewImg';
import Preservation from '@/components/preservation';
import ListComponents from '@/components/listComponents';
export default {
  components: {
    LookExamples,
    Preservation,
    ListComponents,
  },
  data() {
    return {
      id: -1,
      shop: {},
      user: {},
      isHave: false,
      lookexamples: false,
      showIndex: -1,
      dialogVisible: false,
      // 是否夫否一键配置的类别需求
      styleFlag: false,
      nowActive: 0,
    };
  },
  methods: {
    // 获取小程序类别
    getAppStyle() {
      this.$axios.post(this.$api.set.getCategory, {}).then(res => {
        if (res.code == 0) {
          if (res.result.data.findIndex(item => item.name == '服装/鞋/箱包') != -1) {
            this.styleFlag = true;
          } else {
            this.styleFlag = false;
          }
        } else {
          this.styleFlag = false;
          this.$message.warning('请将小程序基础设置配置完整，并配置 商家自营 > 服装/鞋/箱包 类别后，可以一键设置订阅消息');
        }
      });
    },
    // 一键设置
    oneSetting() {
      let that = this;
      this.$axios.post(this.$api.set.addTemplate, {}).then(res => {
        if (res.code == 0) {
          if (res.result.errcode == 0) {
            this.shop = JSON.parse(res.result.sets).store;
            this.user = JSON.parse(res.result.sets).users;
          } else {
            this.$message.error(`${res.result.errmsg}`);
          }
        } else {
          this.$message.error(`${res.msg}`);
        }
      });
    },
    // 选中商家id
    getListItem(item) {
      this.shop.openID = item.mini_openid;
      this.dialogVisible = false;
    },
    // 开启教程设置
    lookexamplesMethond(index) {
      this.$refs.LookExamples.getImgList(index);
      this.lookexamples = true;
    },
    // 关闭教程设置
    hiddenexamples() {
      this.lookexamples = false;
    },
    // 获取页面信息
    getConfig() {
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 6,
        })
        .then(res => {
          if (res.code == 0) {
            if (res.result.info == '') {
              return;
            }
            this.isHave = true;
            let data = res.result.info.sets;
            if (data && data.store) {
              this.shop = data.store;
            }
            if (data && data.users) {
              this.user = data.users;
            }
            this.id = res.result.info.id;
          }
        });
    },
    // 保存
    preservation() {
      let that = this;
      let url = '';
      let obj = {};
      if (this.isHave) {
        url = this.$api.set.Settingedit;
        obj = {
          id: this.id,
          type: 6,
          sets: {
            store: this.shop,
            users: this.user,
          },
        };
      } else {
        url = this.$api.set.Settingadd;
        obj = {
          type: 6,
          sets: {
            store: this.shop,
            users: this.user,
          },
        };
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          that.$message({
            message: '保存成功',
            type: 'success',
          });
          that.isHave = true;
        }
      });
    },
  },
  created() {
    this.getConfig();
    this.getAppStyle();
  },
};
</script>

<style lang="less" scoped>
.subscribeBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px 20px 50px 20px;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  .bigtop {
    height: 64px;
    background: #ebf2ff;
    border: 1px solid #81aeff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding-left: 15px;
    margin-bottom: 18px;
    p {
      margin: 0 220px 0 20px;
      cursor: pointer;
      color: #1467ff;
    }
  }
  .bigBot {
    display: flex;
    .bigBotLeft {
      width: 375px;
    }
    .bigBotRight {
      width: calc(100% - 410px);
      margin-left: 35px;
      .chooseBox {
        display: flex;
        margin-bottom: 20px;
        & > div {
          width: 90px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #bfbfbf;
          border-radius: 0px 2px 2px 0px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          cursor: pointer;
        }
        .isActive {
          color: #1467ff;
          background: #ebf2ff;
          border: 1px solid #1467ff;
        }
      }
    }
  }
}

.firstBox {
  box-sizing: border-box;
  padding-bottom: 20px;
  .topTitle {
    display: flex;
    margin: 20px 0;
    align-items: center;
    h3 {
      width: 190px;
    }
  }
  & > h3 {
    margin-bottom: 20px;
  }
}
.formItem {
  display: flex;
  font-size: 14px;
  color: '#666666';
  margin-bottom: 15px;
  .isOpen {
    width: 690px;
    line-height: 40px;
    color: #9a9a9a;
    white-space: nowrap;
  }
  .title {
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
  }
  .edition {
    padding-top: 3px;
    box-sizing: border-box;
  }
  .looktips {
    color: #477eff;
    cursor: pointer;
    font-size: 14px;
    line-height: 40px;
    margin-left: 20px;
    white-space: nowrap;
  }
}
.el-input {
  max-width: 400px;
}
.last {
  margin-top: 20px;
  margin-left: 200px;
}
</style>
